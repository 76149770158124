const showLogs = true

const streamToBlob = async (stream: ReadableStream) => {
  const chunks = []
  const reader = stream.getReader()
  while (true) {
    const { done, value } = await reader.read()
    if (done) {
      break
    }
    chunks.push(value)
  }
  return new Blob(chunks)
}

export const getTextToSpeechAudioSrc = async (text: string) => {
  // GPT
  // const voice = store.getState().user.teacherVoice
  const voice = undefined
  const resp = await fetch('https://api.openai.com/v1/audio/speech', {
    method: 'POST',
    body: JSON.stringify({
      model: 'tts-1',
      input: text,
      voice: voice ?? 'nova',
      response_format: 'opus',
    }),
    headers: {
      Authorization: 'Bearer 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',
      'Content-type': 'application/json',
    },
  })

  if (resp.body === null) return null

  const reader = resp.body.getReader()

  const audioStream = new ReadableStream({
    async start(controller) {
      while (true) {
        const { done, value } = await reader.read()
        if (done) {
          controller.close()
          break
        }
        controller.enqueue(value)
      }
    },
  })

  const blob = await streamToBlob(audioStream)
  return URL.createObjectURL(blob)
}

function* idGenerator(): Generator<number> {
  let id = 0
  while (true) {
    yield id
    id++
  }
}
const createId = idGenerator()

class AITeacher {
  static isSpeaking = false
  static isVoiceEnable = true
  private static audioEl = new Audio()

  private static subscribes: AITSubscribe[] = []
  private static playQueue: {
    id: number
    src?: string
    visemes?: number[]
    cbOnStart?: () => void
    cbOnFinish?: () => void
    resolve: () => void
    reject: (e: unknown) => void
  }[] = []

  private static enqueue = (args: { text: string; cbOnStart?: () => void; cbOnFinish?: () => void }) => {
    const cbId: number = createId.next().value
    new Promise<void>(async (resolve, reject) => {
      const playObj: (typeof AITeacher.playQueue)[number] = {
        id: cbId,
        cbOnStart: args.cbOnStart,
        cbOnFinish: args.cbOnFinish,
        resolve,
        reject,
      }
      AITeacher.playQueue.push(playObj)
      const audioSrc = await getTextToSpeechAudioSrc(args.text)
      if (audioSrc === null) {
        reject('Error loading audio')
        return
      }
      playObj.src = audioSrc
    })
    AITeacher.playNext()
    return cbId
  }

  private static async playNext() {
    if (!AITeacher.isSpeaking && AITeacher.playQueue.length > 0) {
      const { src, cbOnStart, cbOnFinish, resolve, reject } = AITeacher.playQueue[0]
      if (src === undefined) {
        setTimeout(AITeacher.playNext, 200)
        return
      }
      AITeacher.isSpeaking = true
      AITeacher.playQueue.shift()
      AITeacher.audioEl.src = src
      AITeacher.audioEl.onended = () => {
        cbOnFinish?.()
        AITeacher.subscribes.forEach((s) => s.type === 'speak' && s.cb(false))
        resolve()
      }
      AITeacher.audioEl.onerror = () => {
        AITeacher.subscribes.forEach((s) => s.type === 'speak' && s.cb(false))
        reject('Error loading audio')
      }

      try {
        AITeacher.subscribes.forEach((s) => s.type === 'speak' && s.cb(true))
        await AITeacher.audioEl.play()
        cbOnStart?.()
        await AITeacher.wait(AITeacher.audioEl)
        resolve()
      } catch (error) {
        reject(error)
      } finally {
        AITeacher.isSpeaking = false
        AITeacher.playNext()
      }
    }
  }

  private static async wait(audio: HTMLAudioElement) {
    return new Promise<void>((resolve) => {
      const checkEnded = () => {
        if (audio.ended) {
          audio.removeEventListener('timeupdate', checkEnded)
          resolve()
        }
      }

      audio.addEventListener('timeupdate', checkEnded)
    })
  }

  static speak(args: { text: string; cbOnStart?: () => void; cbOnFinish?: () => void }) {
    if (args.text !== '' && AITeacher.isVoiceEnable) {
      showLogs && console.log(`AITeacher speak: "${args.text.substring(0, 16)}"`)
      return AITeacher.enqueue(args)
    }
  }

  static removeSpeakCallBack = (id: number) => {
    AITeacher.playQueue.forEach((value) => {
      if (value.id === id) {
        value.cbOnStart = undefined
        value.cbOnFinish = undefined
      }
    })
  }

  static stopSpeak() {
    showLogs && console.log('AITeacher stopSpeak')
    AITeacher.audioEl.currentTime = isNaN(AITeacher.audioEl.duration) ? -1 : AITeacher.audioEl.duration
    AITeacher.playQueue = []
    AITeacher.subscribes.forEach((s) => {
      if (s.type === 'speak') s.cb(false)
    })
  }

  static subscribe(args: AITSubscribeArgs) {
    const id: number = createId.next().value
    AITeacher.subscribes.push({ id, ...args })
    return () => AITeacher.unsubscribe(id)
  }

  static unsubscribe(id: number) {
    AITeacher.subscribes = AITeacher.subscribes.filter((s) => {
      return id !== s.id
    })
  }
}

export default AITeacher
