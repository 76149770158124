import md5 from 'md5'

export const BACKEND_DOMAIN = 'https://aiteacherback.splinex.com'
const PASSWORD_SALT = '0dcaffa94f2056c3ccd844109c5f78a2'

const request = async (url: string, options: RequestInit) => {
  try {
    return await fetch(`${BACKEND_DOMAIN}/api${url}`, options).then(async (r) => {
      if (r.ok) {
        const body = await r.json()
        if (body.error === undefined) return body
      }
      return null
    })
  } catch (e) {
    return null
  }
}

const api = {
  postLogin: async (username: string, password: string) => {
    const options = {
      method: 'POST',
      body: JSON.stringify({ username, password: md5(password + PASSWORD_SALT) }),
    }
    return (await request('/login', options)) as User | null
  },
  getUser: async (token: string) => {
    const options = {
      headers: { Authorization: token },
    }
    return (await request('/user', options)) as User | null
  },
  postStartLesson: async (token: string) => {
    const options = {
      method: 'POST',
      headers: { Authorization: token },
      body: JSON.stringify({ lesson_id: 63 }),
    }
    return await request('/start_lesson', options)
  },
  patchResetLesson: async (token: string) => {
    const options = {
      method: 'PATCH',
      headers: { Authorization: token },
      body: JSON.stringify({ lesson: 63 }),
    }
    return await request('/reset_lesson', options)
  },
}

export default api
